import React from 'react';
import { Parallax } from 'react-scroll-parallax';
// components
import ParallaxImage from '../parallax/ParallaxImage';
import SectionTrigger from '../SectionTrigger';
import VideoPlayer from 'src/components/VideoPlayer';

function GamePlanContentMobile({ images, videos }) {
    const [
        gamePlanImg00,
        gamePlanImg01,
        gamePlanImg02,
        gamePlanImg03,
        gamePlanImg04,
        gamePlanImg05,
        gamePlanImg06,
        gamePlanImg07,
        gamePlanImg08,
        gamePlanImg09,
        gamePlanImg10,
        gamePlanImg11,
        gamePlanImg12,
    ] = images;
    const [gamePlanVid01] = videos;
    // const scrollOffset = [40, -20];
    const scrollOffset = [0, 0];

    return (
        <div>
            <SectionTrigger bgColor="#0E0238" textColor="#5B85B1">
                <div className="gp-hero">
                    <div className="gp-hero__container">
                        <div className="gp-hero__header">
                            <img className="gp-hero__header-logo" src={gamePlanImg00} alt="" />
                            <h3 className="main-heading gp-hero__header-hdg">
                                THE GAME PLAN
                            </h3>
                            <p className="main-copy gp-hero__header-copy">
                                TNT
                            </p>
                        </div>
                    </div>
                    <img className="gp-hero__img" src={gamePlanImg01} alt="" />
                </div>
            </SectionTrigger>
            <SectionTrigger
                bgColor="#0E0238"
                className="gp-section gp-section--1"
                textColor="#5B85B1"
            />
            <SectionTrigger
                bgColor="#abbabf"
                className="gp-section gp-section--2"
                textColor="#0E0238"
            />
            <SectionTrigger
                bgColor="#AED1D8"
                className="gp-section gp-section--3"
                textColor="#5B85B1"
            />
            <SectionTrigger
                bgColor="#abbabf"
                className="gp-section gp-section--4"
                textColor="#0E0238"
            />
            <div className="gp__container">
                <p className="main-copy gp__copy gp__copy--1">
                    <span>Produced By OBB</span>
                    THE GAME PLAN is a 6-Part Premium Docu-series exploring Atlanta’s most inspirational Black Entrepreneurs , each driven by a deep purpose and a profound mission to impact the communities they serve.
                </p>

                <h3 className="main-heading gp__heading gp__heading--1">
                    SYNOPSIS
                </h3>

                <p className="main-copy gp__copy gp__copy--2">
                    Hosted by Shaquille O’Neal, in a more serious and heart-felt role than we commonly  associate to the big fella, and joined by a dynamic range of Friends and Guest Stars, including Dwyane Wade, Lisa Leslie, Killer Mike and others, that join Shaq in helping these entreprenuers prepare for long-term success.
                </p>

                <p className="gp__copy gp__copy--5">
                    WATCH THE TRAILER
                </p>
                <VideoPlayer
                    className="gp__vid gp__vid--1"
                    src={gamePlanVid01}
                    poster={gamePlanImg03}
                />
                <h3 className="main-heading solution gp__heading gp__heading--2">
                    SOLUTION
                </h3>

                <p className="main-copy gp__copy gp__copy--3">
                    Shot entirely on location in Atlanta, the series deploys a rich, cinematic approach to filmmaking, incorporating art-directed interviews, elevated verité, and highly stylized B-Roll to elevated it beyond the traditional business makeover show.
                </p>

                <p className="gp__copy gp__copy--6">
                    Killer Mike
                </p>

                <p className="gp__copy gp__copy--7">
                    Dwyane Wade
                </p>

                <blockquote className="blockquote gp__blockquote gp__blockquote--1">
                    “Atlanta-based entrepreneurs receive business advice and identify new opportunities …”
                </blockquote>
                <h3 className="main-heading approach gp__heading gp__heading--3">
                    SOLUTION
                </h3>

                <p className="main-copy gp__copy gp__copy--4">
                    Unlike the traditional reality format, this is a premium documentary approach. Each episode dives deeply into the backstory, the inciting incident that gave birth to the idea and the unfolding of the mission that ultimately informed each entrepreneur’s business plan and ultimate purpose in life.  We’ll witness Shaq and friends collaborating on strategies to help each business take things to the next level, while a unique collaboration  between the show and each business that resulted in a public facing product roll out on Bleacher Report’s shopping app.
                </p>

                <blockquote className="blockquote gp__blockquote gp__blockquote--2">
                    “…to sharpen their “game plan” from NBA legend Shaquille O’Neal and some of his successful celebrity friends.”
                </blockquote>

                <h3 className="main-heading impact gp__heading gp__heading--4">
                    IMPACT IMPACT
                </h3>

                <p className="main-copy gp__copy gp__copy--8">
                    The show  was a unique play for both TNT and for our dynamic host Shaquille O’Neal, both taking a slightly more serious and cinematic tone, that garnered a larger audience and a range of critical acclaim.
                </p>

                <img
                    className="gp__img gp__img--2"
                    src={gamePlanImg02}
                    alt=""
                />
                <img
                    className="gp__img gp__img--3"
                    src={gamePlanImg04}
                    alt=""
                />
                <Parallax className="gp__img gp__img--4" y={scrollOffset}>
                    <ParallaxImage src={gamePlanImg05} alt="" />
                </Parallax>
                <img
                    className="gp__img gp__img--5"
                    src={gamePlanImg06}
                    alt=""
                />
                <Parallax className="gp__img gp__img--6" y={scrollOffset}>
                    <ParallaxImage src={gamePlanImg07} alt="" />
                </Parallax>
                <img
                    className="gp__img gp__img--7"
                    src={gamePlanImg08}
                    alt=""
                />
                <img
                    className="gp__img gp__img--8"
                    src={gamePlanImg09}
                    alt=""
                />
                <img
                    className="gp__img gp__img--9"
                    src={gamePlanImg10}
                    alt=""
                />
                <img
                    className="global-box-shadow gp__img gp__img--10"
                    src={gamePlanImg11}
                    alt=""
                />
                <img
                    className="global-box-shadow gp__img gp__img--11"
                    src={gamePlanImg12}
                    alt=""
                />
            </div>
            <SectionTrigger
                bgColor="#F3766A"
                className="gp-section"
                textColor="#fff"
            />
        </div>
    )
}

export default GamePlanContentMobile;