import { useStaticQuery, graphql } from 'gatsby';

export default () =>
  useStaticQuery(graphql`
    {
      allCloudinaryMedia(
        filter: { tags: { eq: "gameplan-case-study" } }
        sort: { fields: public_id, order: ASC }
      ) {
        edges {
          node {
            resource_type
            secure_url
          }
        }
      }
    }
  `);
